import authenticatedAxiosInstance from '../axios/axios-authorized';

export const getTransitAgencyAccommodationTypes = async () => {
  const response = await authenticatedAxiosInstance.axios.get('/allTransitAgencies/accomodations');

  return response?.data;
};

export const getTransitAgencyPassengerTypes = async (transitAgencyId) => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/transitagencies/${transitAgencyId}/passengerTypes`
  );

  return response.data;
};

export const getTransitAgencyNoFarePassengerTypes = async (transitAgencyId) => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/transitagencies/${transitAgencyId}/no-fare-passenger-types`
  );

  return response?.data;
};

export const getTransitAgencyFeatures = async (transitAgencyId) => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/features?transitAgencyId=${transitAgencyId}`
  );

  return response?.data;
};

export const saveTransitAgencyFeatures = async (transitAgencyId, reqBody) => {
  const response = await authenticatedAxiosInstance.axios.put(
    `/transit-agency/${transitAgencyId}/features`,
    reqBody
  );

  return response?.data;
};

export const getTransitAgencyGeoJson = async (transitAgencyId) => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/transitagencies/${transitAgencyId}/geojson`
  );

  return response.data;
};

export const getTransitAgencyDetails = async (transitAgencyId) => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/transitagencies/${transitAgencyId}`
  );

  return response.data;
};

export const getTransitAgencyExtraDetails = async (transitAgencyId) => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/transitagencies/${transitAgencyId}/extraDetails`
  );

  return response.data;
};

export const goLive = async (taId) => {
  const response = await authenticatedAxiosInstance.axios.patch('/transitagency/live', {
    transit_agency_id: taId
  });

  return response;
};

export const getTransitAgencyAnalyticsURL = async (transitAgencyId) => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/transitagencies/${transitAgencyId}/analytics`
  );

  return response?.data;
};

export const updateTransitAgency = async (transitAgencyId, reqBody) => {
  return (
    await authenticatedAxiosInstance.axios.patch(`/transitagencies/${transitAgencyId}`, reqBody)
  ).data;
};

export const getTransitAgencyMemberships = async () => {
  const response = await authenticatedAxiosInstance.axios.get('/transitagencies/membership');

  return response.data;
};

export const switchCurrentTransitAgency = async (selectedTA) => {
  return (
    await authenticatedAxiosInstance.axios.post('/transitagencies/switchCurrent', { selectedTA })
  ).data;
};

export const getAvailablePaymentTypesForTransitAgency = async (transitAgencyId) => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/transitagencies/${transitAgencyId}/paymentTypes`
  );

  return response.data;
};

export const getCustomColors = async (transitAgencyId) => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/transit-agency/${transitAgencyId}/colors`
  );

  return response.data;
};

export const updateCustomColors = async (transitAgencyId, colors) => {
  const response = await authenticatedAxiosInstance.axios.post(
    `/transit-agency/${transitAgencyId}/colors`,
    colors
  );

  return response.data;
};

export const getTransitAgencyVehicleExtraDetails = async (transitAgencyId) => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/transit-agency/${transitAgencyId}/vehicle-extra-details`
  );

  return response?.data;
};

export const getTransitAgencyDriverExtraDetails = async (transitAgencyId) => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/transit-agency/${transitAgencyId}/driver-extra-details`
  );

  return response?.data;
};
